import axios from 'axios';
import CryptoJS from 'crypto-js'
import dayjs from 'dayjs';
//import { showMessage } from 'app/store/actions/fuse'
import duration from 'dayjs/plugin/duration';
export const GET_ORIGENES = '[ CP ORIGENES ]  GET_ORIGENES';
export const GET_ORIGENES_DATA = '[ CP ORIGENES ]  GET_ORIGENES_DATA'
export const UPDATE_ORIGENES_DATA = '[ CP ORIGENES ] UPDATE_ORIGENES_DATA'
export const SAVE_ORIGENES_DATA = '[ CP ORIGENES ] SAVE_ORIGENES_DATA'
export const CLEAR_ORIGENES_DATA = '[CP ORIGENES ] CLEAR_ORIGENES_DATA'
export const REMOVE_DOCS = '[ CP ORIGENES ] ORIGENES REMOVE DOCS'

export const UPDATE_COMPARA_DATA = "[UPDATE_COMPARA_DATA] UPDATE_COMPARA_DATA"
export const UPDATE_COMPARA_BUSCANDO = "[UPDATE_COMPARA_BUSCANDO] UPDATE_COMPARA_BUSCANDO"

dayjs.extend(duration);

const encryptionKey = 'frontkeys';
const apiUrl = process.env.REACT_APP_API_URL;
const headers = {
	'Content-Type': 'application/json'
}




/////compara 


export function updateCompara(params) {

	return (dispatch) => {
		return dispatch({
			type: UPDATE_COMPARA_DATA,
			payload: params
		})
	}
}

export function getCategorias(c) {
	//get custom setting

	return (dispatch) => {

		dispatch(updateCompara({ id: 'categorias', value: c }))

		/*
		axios.get(
			apiUrl + '/svc/landing')
			.then((r) => {
				//console.log('categorias --> ', decrypt(r.data.data))
				dispatch(updateCompara({ id: 'categorias', value: [] })) //r.data.data 
			})
		*/

	}
}

export function getTerminos(t) {
	//get custom setting

	return (dispatch) => {
		//console.log(apiUrl + '/apps', props.pagename)
		//dispatch(setScrap(true))

		dispatch(updateCompara({ id: 'obteniendo', value: false }));
		dispatch(updateCompara({ id: 'urlstream', value: '' }));
		dispatch(updateCompara({ id: 'buscando', value: null }));
		dispatch(updateCompara({ id: 'resultados', value: [] }));
		dispatch(updateCompara({ id: 'terminos', value: t }))
		/*
		axios.get(
			apiUrl + '/compara/terminos')
			.then((r) => {
				
				//console.log('terminos --> ',r.data.data)
				dispatch(updateCompara({ id: 'terminos', value: [] }))// r.data.data
			})
		*/

	}
}

export function updateBuscando(v) {

	return dispatch => {

		dispatch({
			type: UPDATE_COMPARA_BUSCANDO,
			payload: v
		})

	}

}

export function comparaPrecio() {

	//get custom setting

	return (dispatch, getState) => {

		const { termino, categoria } = getState().origenes.origenes

		dispatch(updateBuscando(true))
		dispatch(updateCompara({ id: 'obteniendo', value: true }))
		dispatch(updateCompara({ id: 'priceSortMejores', value: null }));

		if (termino === '' || categoria === '') {
			//console.log('Falta termino o categoria ', termino, categoria)
			dispatch(updateBuscando(false))
			return false
		}

		//console.log('Comparando')
		//console.log(categoria, termino)

		dispatch(updateCompara({ id: 'urlstream', value: '' }));
		dispatch(updateCompara({ id: 'resultados', value: [] }));

		dispatch(updateCompara({ id: 'origenes', value: 0 }));
		dispatch(updateCompara({ id: 'totales', value: 0 }));

		dispatch(updateCompara({ id: 'duracion', value: 0 }));

		axios.post(
			apiUrl + '/compara/pr',
			{
				busca: termino,
				categoria: categoria
			},
			headers
		)
			.then(async (r) => {

				//console.log(r.data.data)

				r.data.data = await decrypt(r.data.data)

				if (!r.data.data) {
					//console.log('error')
					return
				}

				// Verifica si r.data.data es una cadena
				if (typeof r.data?.data === 'string') {
					//console.log('--> url stream')
					dispatch(updateCompara({ id: 'urlstream', value: r.data.data }));

				} else if (r.data?.data[0] && r.data?.data[0].resultados) {

					const re = r.data?.data[0].resultados
					//console.log('--> resultados cache ', re)
					dispatch(resultadosFinal(re))
					dispatch(updateBuscando(false))

					setTimeout(() => dispatch(updateCompara({ id: 'obteniendo', value: false })), 10)

				}




			})
	}

}

export function initResults(r) {

	return async (dispatch, getState) => {

		const hasError = await manejaError(r)

		if (hasError) {
			return false
		}

		dispatch(updateCompara({ id: 'resultados', value: [] }));

		dispatch(updateBuscando(true))

		// Verifica si r.data.data es una cadena
		if (typeof r === 'string') {
			//console.log('--> url stream')
			dispatch(updateCompara({ id: 'urlstream', value: r }));

		} else if (r[0] && r[0].resultados) {

			const re = r[0].resultados
			//console.log('--> resultados cache ', re)
			dispatch(resultadosFinal(re))
			dispatch(updateBuscando(false))

			setTimeout(() => dispatch(updateCompara({ id: 'obteniendo', value: false })), 10)

		}

	}

}

async function manejaError(r) {
	//chequea el esultado y si error muestra aviso
	try {
		//console.log(r)
		return false
	}
	catch (e) {
		//console.log(e.stack)
		return false
	}

}

export function toMejorResultado(indice, producto) {

	//pasa a mejor resultado el id del indice

	return (dispatch, getState) => {

		const { resultados } = getState().origenes.origenes;

		//console.log(resultados, indice, producto)

		let nr = [...resultados]
		nr[indice].resultados = nr[indice].resultados.filter(x => x.id !== producto.id)
		nr[indice].resultados.push(nr[indice].mejorResultado)
		nr[indice].mejorResultado = producto
		//obtine el origen desde resultados
		//obtine el mejorresultado
		//setea el nuevo mejor resultado con el el id
		//agrega el de mejor resultado a resultados
		//quita el producto de resultados
		//actualiza resultados

		dispatch(updateCompara({ id: 'resultados', value: nr }));
	}

}

export function quitarOrigenResultado(indice) {

	//pasa a mejor resultado el id del indice

	return (dispatch, getState) => {

		const { resultados } = getState().origenes.origenes;
		dispatch(updateCompara({ id: 'resultados', value: resultados.filter((_, i) => i !== indice) }));

	}

}

export function quitarProductoResultado(indice, id) {

	//pasa a mejor resultado el id del indice

	return (dispatch, getState) => {


		const { resultados } = getState().origenes.origenes;
		let n = [...resultados]
		let nn = n[indice].resultados.filter((_, i) => _.id !== id)
		n[indice]['resultados'] = nn

		//console.log(n[indice], nn, indice, id)
		dispatch(updateCompara({ id: 'resultados', value: n }));

	}

}




export function resultadosFinalOld(nuevos) {

	//procesa resultados

	return (dispatch, getState) => {

		const { resultados } = getState().origenes.origenes
		//console.log('resulados -> ', resultados, nuevos)

		let o = []
		let t = 0

		nuevos.forEach(x => {
			//console.log(x)
			//&& x.obtuvo.resultados && x.obtuvo.resultados.length > 0
			if (x.obtuvo.mejorResultado && x.obtuvo.mejorResultado.title && x.obtuvo.mejorResultado.title !== '') {
				/*
				if (resultados) {
					//console.log(resultados.filter(y => y.nombre === x.nombre).length)
				}
				*/
				if (!resultados || resultados.filter(y => y.nombre === x.nombre).length === 0) {

					x.obtuvo['nombre'] = x.nombre
					x.obtuvo.resultados = x.obtuvo.resultados.filter(y => y.title !== x.obtuvo.mejorResultado.title && y.title !== '')

					//x.obtuvo.resultados = ordenarResultados(x.obtuvo.resultados, 'price', 'asc')
					o.push(x.obtuvo)
					t = (x.obtuvo.resultados.length + 1) + t
					//console.log(x.obtuvo.resultados.length,(x.obtuvo.resultados.length + 1), t)
				}
			}
		})


		//console.log(o)
		//o = ordenarArray(o, 'price', 'asc')

		dispatch(updateCompara({ id: 'resultados', value: o }));

		dispatch(updateCompara({ id: 'origenes', value: o.length }));
		dispatch(updateCompara({ id: 'totales', value: t }));




	}
}


export function resultadosFinal(nuevos, crypt = false) {
	// procesa resultados
	return async (dispatch, getState) => {
		const { resultados, totales } = getState().origenes.origenes;

		//console.log('resultados -> ', resultados, nuevos);

		let o = resultados ? [...resultados] : []; // Copia de los resultados existentes o un array vacío si no hay
		let t = 0//Number(totales) || 0;



		if (nuevos && crypt === true) {
			try {
				nuevos = await decrypt(nuevos); // Asegúrate de que `decrypt` esté disponible
			} catch (error) {
				console.error('Error al desencriptar:', error);
				return; // Salir si hay un error durante la desencriptación
			}
		}



		nuevos.forEach(x => {
			if (x.obtuvo.mejorResultado && x.obtuvo.mejorResultado.title && x.obtuvo.mejorResultado.title !== '') {
				// Verificar si el resultado ya existe
				const existeEnResultados = o.some(y => y.nombre === x.nombre);

				//console.log(existeEnResultados, o, x.nombre)

				if (!existeEnResultados) {
					x.obtuvo['nombre'] = x.nombre;
					// Filtra resultados que no coincidan con el mejor resultado
					x.obtuvo.resultados = x.obtuvo.resultados.filter(y => y.title !== x.obtuvo.mejorResultado.title && y.title !== '');

					// Agrega el resultado nuevo a la lista
					o.push(x.obtuvo);
					// Suma la cantidad de resultados obtenidos
					//console.log(x.obtuvo.resultados, x.obtuvo.resultados.length, t)
					t = x.obtuvo.resultados.length + t //parseInt((x.obtuvo.resultados.length + 1) + Number(t))
				}
			}
		});


		//o = ordenarArray(o, 'price', 'asc')

		// Despachar los resultados actualizados
		dispatch(updateCompara({ id: 'resultados', value: o }));
		dispatch(updateCompara({ id: 'origenes', value: o.length }));
		dispatch(updateCompara({ id: 'totales', value: t + o.length }));
	};
}



//ordenes

function ordenarArray(array, campo, direccion = 'asc') {
	return array.sort((a, b) => {
		// Extraer el valor del campo especificado y convertirlo a número si es necesario
		const valorA = parseFloat(a.mejorResultado[campo].replace(/[^\d,]/g, '').replace(',', '.'));
		const valorB = parseFloat(b.mejorResultado[campo].replace(/[^\d,]/g, '').replace(',', '.'));

		// Comparar según la dirección especificada
		if (direccion === 'asc') {
			return valorA - valorB;
		} else if (direccion === 'desc') {
			return valorB - valorA;
		} else {
			throw new Error("La dirección debe ser 'asc' o 'desc'");
		}
	});
}

function ordenarResultados(array, campo, direccion = 'asc') {
	return array.sort((a, b) => {
		// Extraer el valor del campo especificado y convertirlo a número si es necesario
		const valorA = parseFloat(a[campo].replace(/[^\d,]/g, '').replace(',', '.'));
		const valorB = parseFloat(b[campo].replace(/[^\d,]/g, '').replace(',', '.'));

		// Comparar según la dirección especificada
		if (direccion === 'asc') {
			return valorA - valorB;
		} else if (direccion === 'desc') {
			return valorB - valorA;
		} else {
			throw new Error("La dirección debe ser 'asc' o 'desc'");
		}
	});
}


//nuevas
function ordenarOrigenesPorPrecio(data, direccion = "asc") {
	// Clonar el array de orígenes para no modificar el original
	const origenesOrdenados = [...data.origenes];

	origenesOrdenados.sort((a, b) => {
		// Extraer los precios de mejorResultado y convertirlos a números
		const precioA = parseFloat(a.obtuvo.mejorResultado.price.replace(/[.,]/g, ''));
		const precioB = parseFloat(b.obtuvo.mejorResultado.price.replace(/[.,]/g, ''));

		// Comparar los precios según la dirección
		return direccion === "desc" ? precioB - precioA : precioA - precioB;
	});

	return origenesOrdenados;
}

function ordenarResultadosPorPrecio(origenes, direccion = "asc") {
	// Clonar el array de orígenes para no modificar el original
	const origenesConResultadosOrdenados = origenes.map(origen => {
		const resultadosOrdenados = [...origen.obtuvo.resultados];

		resultadosOrdenados.sort((a, b) => {
			// Extraer los precios de cada resultado y convertirlos a números
			const precioA = parseFloat(a.price.replace(/[.,]/g, ''));
			const precioB = parseFloat(b.price.replace(/[.,]/g, ''));

			// Comparar los precios según la dirección
			return direccion === "desc" ? precioB - precioA : precioA - precioB;
		});

		return {
			...origen,
			obtuvo: {
				...origen.obtuvo,
				resultados: resultadosOrdenados
			}
		};
	});

	return origenesConResultadosOrdenados;
}

function ordenarResultadosPorCoincidencias(origenes, terminoDeBusqueda) {
	// Convertir el término de búsqueda a un array de palabras para comparar
	const palabrasDeBusqueda = terminoDeBusqueda.toLowerCase().split(/\s+/);

	const origenesConResultadosOrdenados = origenes.map(origen => {
		const resultadosOrdenados = [...origen.obtuvo.resultados];

		resultadosOrdenados.sort((a, b) => {
			const coincidenciasA = contarCoincidencias(a.title, palabrasDeBusqueda);
			const coincidenciasB = contarCoincidencias(b.title, palabrasDeBusqueda);

			// Ordenar de mayor a menor número de coincidencias
			return coincidenciasB - coincidenciasA;
		});

		return {
			...origen,
			obtuvo: {
				...origen.obtuvo,
				resultados: resultadosOrdenados
			}
		};
	});

	return origenesConResultadosOrdenados;
}

// Función auxiliar para contar coincidencias de palabras en un título
function contarCoincidencias(titulo, palabrasDeBusqueda) {
	const palabrasDelTitulo = titulo.toLowerCase().split(/\s+/);
	let coincidencias = 0;

	palabrasDeBusqueda.forEach(palabra => {
		if (palabrasDelTitulo.includes(palabra)) {
			coincidencias++;
		}
	});

	return coincidencias;
}


///nuevas


export function resultadosOrden(options) {

	//console.log('ordena -> ', options)
	//procesa resultados

	return (dispatch, getState) => {

		const { termino, resultados, priceSortMejores, priceSortResultados } = getState().origenes.origenes
		let nr = [...resultados]

		/*
		//console.log(nr)


		let ordenar = {
			termino: termino,
			ordenar: []
		}


		nr.forEach(element => {
			//console.log(element)
			//element.resultados = element.resultados.push(element.mejorResultado)
			ordenar.ordenar.push({
				origen: element.origen,
				resultados: element.resultados
			})
		});


		//console.log(ordenar)

		axios.post(
			apiUrl + '/compara/ordena', ordenar,
			headers
		)
			.then((r) => {
				//console.log(r.data.data)
				dispatch(updateCompara({ id: 'resultados', value: r.data.data }));
				setTimeout(() => dispatch(updateBuscando(false)), 1000)
			})
		*/


		dispatch(updateBuscando(true))

		const nuevoPriceSortMejores = priceSortMejores === 'asc' ? 'desc' : 'asc'


		dispatch(updateCompara({ id: 'priceSortMejores', value: nuevoPriceSortMejores }));

		const nuevoPriceSortResultados = priceSortResultados === 'asc' ? 'desc' : 'asc'
		dispatch(updateCompara({ id: 'priceSortResultados', value: nuevoPriceSortResultados }));

		nr.forEach(element => {


			//console.log(element.resultados, element.mejorResultado)
			//console.log(ordenarResultados(element.resultados, 'price', 'asc'))
			element.juntos = []
			element.juntos.push(element.mejorResultado)
			element.resultados.forEach((x) => {
				element.juntos.push(x)
			})
			//console.log(element.juntos)
			//console.log('--')

			//element.resultados = element.resultados.push(element.mejorResultado)
			element.resultados = ordenarResultados(element.resultados, 'price', nuevoPriceSortResultados)

		});

		nr = ordenarArray(nr, 'price', nuevoPriceSortMejores)

		//console.log(asignarOrden(nr, 'price'))


		//dispatch(resultadosFinal(nr))
		dispatch(updateCompara({ id: 'resultados', value: nr }));
		setTimeout(() => dispatch(updateBuscando(false)), 1000)

	}
}


function decrypt(encrypted) {
	try {

		var decryptedData = CryptoJS.AES.decrypt(encrypted, encryptionKey).toString(
			CryptoJS.enc.Utf8
		)

		return JSON.parse(decryptedData)
	} catch (error) {
		console.error('Error al descifrar los datos:', error);
		throw new Error('Fallo en la operación de descifrado');
	}
}

function asignarOrden(array, campo, direccion = 'asc') {
	console.log(array, campo)
	// Crear una copia del array para no modificar el array original
	const arrayConOrden = array.map((item, index) => ({
		...item,
		originalIndex: index
	}));

	// Ordenar la copia del array basada en el campo especificado
	/*
	arrayConOrden.sort((a, b) => {
		// Extraer el valor del campo especificado y convertirlo a número si es necesario
		const valorA = parseFloat(a[campo].replace(/[^\d,]/g, '').replace(',', '.'));
		const valorB = parseFloat(b[campo].replace(/[^\d,]/g, '').replace(',', '.'));

		// Comparar según la dirección especificada
		if (direccion === 'asc') {
			return valorA - valorB;
		} else if (direccion === 'desc') {
			return valorB - valorA;
		} else {
			throw new Error("La dirección debe ser 'asc' o 'desc'");
		}
	});
*/

	// Asignar números de orden a los elementos según el índice original
	return arrayConOrden.map((item, index) => ({
		...array[item.originalIndex],
		orden: index + 1 // Orden comienza en 1
	}));
}

