import { createTheme } from '@mui/material/styles';
import { lightBlue, red } from '@mui/material/colors'

const dark = true

const fuseDark = {
  50: '#e3e6e8',
  100: '#bac0c5',
  200: '#8c969f',
  300: '#5e6c78',
  400: '#3c4d5b',
  //500: '#192d3e',
  500: 'rgba(33, 150, 243, 0.8)',
  600: '#162838',
  700: '#122230',
  800: '#0e1c28',
  900: '#08111b',
  A100: '#5b9aff',
  A200: '#287bff',
  A400: '#005ef4',
  A700: '#0054da'
}

// Create a theme instance.
const theme = createTheme({
  palette: {
			mode: 'dark',
			primary: fuseDark,
      background: {
						//paper: '#1E2125',
						default: '#121212'
					},
			secondary: {
				light: lightBlue[400],
				main: lightBlue[600],
				dark: lightBlue[700]
			},
			error: red
		},
		status: {
			danger: 'orange'
		}
})
/*
const theme = createTheme({
    /*	palette: {
			type: 'dark',
			primary: fuseDark,
			secondary: {
				light: lightBlue[400],
				main: '#f1f1f1', //lightBlue[600],
				dark: lightBlue[700]
			},
			error: red
		},
		status: {
			danger: 'orange'
		}
  *
  palette: {
    mode: dark ? 'dark' : 'light',
    
    //primary: fuseDark,
    error: {
      main: red.A400
    },
    background: {
       default: '#121212',
       //paper:  "#424242",
    }
    
  }
})
*/
export default theme
