// components/AdBlockRecovery.js
import React, { useEffect } from 'react';
import Script from 'next/script';

const AdBlockRecovery = () => {
  useEffect(() => {
    // Función para crear un iframe invisible
    const signalGooglefcPresent = () => {
      if (!window.frames['googlefcPresent']) {
        if (document.body) {
          const iframe = document.createElement('iframe');
          iframe.style = 'width: 0; height: 0; border: none; z-index: -1000; left: -1000px; top: -1000px;';
          iframe.style.display = 'none';
          iframe.name = 'googlefcPresent';
          document.body.appendChild(iframe);
        } else {
          setTimeout(signalGooglefcPresent, 0);
        }
      }
    };

    // Llamar a la función para crear el iframe
    signalGooglefcPresent();
  }, []);

  return (
    <>
      <Script
        src="https://fundingchoicesmessages.google.com/i/pub-7542824617421637?ers=1"
        strategy="afterInteractive"
        nonce="dOtayl0BeLbRzRJspdtxbg"
      />
      <Script
        id="googlefc-present"
        nonce="dOtayl0BeLbRzRJspdtxbg"
        dangerouslySetInnerHTML={{
          __html: `(function() {function signalGooglefcPresent() {if (!window.frames['googlefcPresent']) {if (document.body) {const iframe = document.createElement('iframe'); iframe.style = 'width: 0; height: 0; border: none; z-index: -1000; left: -1000px; top: -1000px;'; iframe.style.display = 'none'; iframe.name = 'googlefcPresent'; document.body.appendChild(iframe);} else {setTimeout(signalGooglefcPresent, 0);}}}signalGooglefcPresent();})();`,
        }}
      />
    </>
  );
};

export default AdBlockRecovery;
