// components/PopAds.js
import Head from 'next/head';
import Script from 'next/script';

const PopAds = () => {
    return (
        <>
            <Head>
                {/* Puedes agregar otras etiquetas dentro de <Head> aquí si es necesario */}

                <Script
                    id="custom-script"
                    type="text/javascript"
                    strategy="beforeInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
                    (function(){
                        var x = window,
                            e = "fa910a599383a8d744a43653b14b8377",
                            n = [
                                ["siteId", 180 * 463 - 51 + 474 + 5047543],
                                ["minBid", 0],
                                ["popundersPerIP", "0"],
                                ["delayBetween", 0],
                                ["default", false],
                                ["defaultPerDay", 0],
                                ["topmostLayer", "auto"]
                            ],
                            i = [
                                "d3d3LmludGVsbGlwb3B1cC5jb20vY21vZGVzdG1hcHMubWluLmNzcw==",
                                "ZDNtcjd5MTU0ZDJxZzUuY2xvdWRmcm9udC5uZXQvbS9pbmF0aXZlLmhpc3RvcnkubWluLmpz"
                            ],
                            v = -1,
                            p, q,
                            a = function() {
                                clearTimeout(q);
                                v++;
                                if (i[v] && !(1751317748000 < (new Date).getTime() && 1 < v)) {
                                    p = x.document.createElement("script");
                                    p.type = "text/javascript";
                                    p.async = true;
                                    var j = x.document.getElementsByTagName("script")[0];
                                    p.src = "https://" + atob(i[v]);
                                    p.crossOrigin = "anonymous";
                                    p.onerror = a;
                                    p.onload = function() {
                                        clearTimeout(q);
                                        x[e.slice(0, 16) + e.slice(0, 16)] || a();
                                    };
                                    q = setTimeout(a, 5000);
                                    j.parentNode.insertBefore(p, j);
                                }
                            };
                        if (!x[e]) {
                            try {
                                Object.freeze(x[e] = n);
                            } catch (e) {}
                            a();
                        }
                    })();
                    `
                    }}
                />
            </Head>
        </>
    );
};

export default PopAds;
