/** !!!!! aplica el persist en ./store */
import { useMemo } from 'react';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import origenes from './reducers';

let store;

const rootReducer = combineReducers({
  // Aquí se agregan más reducers si los tienes
  origenes,
});

const initStore = (preloadedState = {}) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(thunkMiddleware),
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production', // DevTools solo en desarrollo
  });
};

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // Si hay un estado precargado y ya existe el store, fusionamos los estados
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    store = undefined;
  }

  // Para SSR y SSG, siempre se crea un nuevo store
  if (typeof window === 'undefined') return _store;

  // En el cliente, solo creamos el store una vez
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
