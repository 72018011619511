import PropTypes from 'prop-types';
import Head from 'next/head';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import theme from '../src/theme';
import createEmotionCache from '../src/createEmotionCache';
import 'tailwindcss/tailwind.css';
import { Provider } from 'react-redux';
import { useStore } from '../src/store/store';
import '../styles/global.css';
import '../styles/slide.css';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import * as gtag from '../src/lib/gtag';
import ConsentBanner from '../src/components/landing/ConsentBanner';
import AdBlockRecovery from '../src/components/landing/AdBlockRecovery';

import PopAds from '../src/components/landing/PopAds';

const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const store = useStore(pageProps.initialReduxState);

  const router = useRouter();
  const [consentGranted, setConsentGranted] = useState(false);

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (consentGranted) {
        gtag.pageview(url);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, consentGranted]);

  const handleConsent = (consent) => {
    if (consent === 'granted') {
      gtag.consentGranted();
    } else {
      gtag.consentDenied();
    }
    setConsentGranted(consent === 'granted');
  };

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <AdBlockRecovery />


        {process.env.NODE_ENV === 'production' && (
          <>
            {/*
            <Script
              id="ms_clarity"
              strategy="afterInteractive"
            >
              {`
                  (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                  })(window, document, "clarity", "script", "j2nuurgyb1");
                `}
            </Script>
          */}
            <Script
              src="https://www.googletagmanager.com/gtag/js?id=GTM-55KDJDQD"
              strategy="afterInteractive"
            />

            <Script
              id="google-analytics"
              strategy="afterInteractive">
              {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'GTM-55KDJDQD', { 'anonymize_ip': true });
                `}
            </Script>
          </>
        )}


        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ConsentBanner onConsent={handleConsent} />
          <Component {...pageProps} />
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
