import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, Link, Container } from '@mui/material';

const ConsentBanner = ({ onConsent }) => {
    const [isVisible, setIsVisible] = useState(false);
    
    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        } else {
            onConsent(consent);
        }
    }, [onConsent]);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'granted');
        setIsVisible(false);
        onConsent('granted');
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'denied');
        setIsVisible(false);
        onConsent('denied');
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                backgroundColor: '#333',
                color: '#fff',
                padding: '16px',
                //textAlign: 'center',
                zIndex: 1000,
            }}
        >
            <Container maxWidth="md">
                <Typography variant="body1" sx={{ marginBottom: '8px' }}>
                    Utilizamos cookies para mejorar tu experiencia en nuestro sitio, mostrar anuncios personalizados y analizar el tráfico.
                    Obtén más información en nuestra{' '}
                    <Link href="/politicas-de-privacidad" color="inherit" underline="always">
                        Política de Privacidad
                    </Link>{' '}
                    y{' '}
                    <Link href="/politicas-de-cookies" color="inherit" underline="always">
                        Política de Cookies
                    </Link>.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'start', gap: '16px' }} className="mt-4">
                    <Button variant="contained" color="primary" onClick={handleAccept}>
                        Aceptar
                    </Button>
                    <Button variant="outlined" color="inherit" onClick={handleReject}>
                        Rechazar
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default ConsentBanner;
